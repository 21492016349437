<template>
  <v-app>
      <!-- <navs></navs> -->
      <!-- <router-view ></router-view> -->
      <!-- <footers class="mt-5"></footers> -->
              <template v-if="!isMobile">
                <v-img 
                    src="https://coneldoradovalesoro.com.mx/nada.jpg"
                    class="grey darken-4 "
                ></v-img>
            </template>
            <template v-else>
                <v-img 
                     src="https://coneldoradovalesoro.com.mx/nada_mobile.jpg"
                    class="grey darken-4 "
                ></v-img>
            </template>
  </v-app>
</template>

<script>
// import footers from './components/Footer';
// import navs from './components/Nav';
export default {
  name: 'App',
    data () {
      return {
         isMobile: this.$mobile(),
      }
    }
  // components: {
  //   footers,
  //   navs
  // },
}
</script>
