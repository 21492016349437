<template>
  <div>
        <v-container class="mt-5">
        <div class="text-center mt-5" style="color:#000;">
            <h1>Bienvenido Administrador</h1>
            <h5 style="color:rgb(248 172 0);">CON EL DORADO VALES ORO</h5>
        </div>
            <b-row class="justify-content-center mt-5">
                <b-col cols="12" md="8" >
                    <v-card elevation="4" shaped outlined>                       
                        <v-card-text>
                            <p class="text-center" >Administrador de reportes.</p>            
                            <b-row class="justify-content-center mt-5 text-center">
                                <b-col cols="12" md="6" >
                                    <h5 class="text-center mt-0" style="color:#000;">Reporte base de datos</h5> 
                                    <br>
                                    <v-btn
                                        class="ma-2"
                                        outlined
                                        color="#ea4c20"
                                        @click="reporteExcel()"
                                        >
                                        Descargar <b-spinner v-if="loader" small></b-spinner>
                                    </v-btn> 
                                </b-col>
                                <b-col cols="12" md="6" >
                                    <h5 class="text-center mt-0" style="color:#000;">Reporte fotográfico tickets</h5> 
                                    <br>
                                    <v-btn
                                        class="ma-2"
                                        outlined
                                        color="#ea4c20"
                                        @click="reportePdf()"
                                        >
                                        Descargar <b-spinner v-if="loader2" small></b-spinner>
                                    </v-btn> 
                                </b-col>
                                <b-col cols="12" md="6" >
                                    <hr>
                                    <h5 class="text-center mt-0" style="color:#000;">Primeros 15 lugares del Ranking</h5> 
                                        <div class="table-responsive">
                                            <table class="table table-hover table-striped mt-3" >
                                                <thead class="text-white text-center" style="background:#ea4c20;">
                                                <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Nombre</th>
                                                <th scope="col">Puntos</th>
                                                </tr>
                                                </thead>
                                                <tbody v-if="listado.length > 0">
                                                    <tr v-for="pro in listado" :key="pro.id" class=" text-center">
                                                        <td>{{pro.ranking}}</td>
                                                        <td>{{pro.nombre}}</td>
                                                        <td>{{pro.puntos}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <v-alert  v-if="listado.length == 0" style="margin-left:-2px;"
                                            border="left"
                                            colored-border
                                            type="error"
                                            elevation="2"
                                            >
                                            Por el momento no hay registros.
                                        </v-alert>  
                                </b-col>
                            </b-row>
                        </v-card-text>                          
                    </v-card>
                    <br>
                    <hr>
                    <br>
                    <br>
                    <br><br><br><br><br>
                </b-col>
            </b-row>
    </v-container> 
  </div>
</template>

<script>
import Swal from 'sweetalert2'
export default {
 data () {
      return {
          arrayAd:[],
          loader:false,
          loader2:false
      }
 },
 computed:{
   listado(){       
        return this.arrayAd.filter(item => {
             return item.ranking <= 15 ;
        });
     },
 },
 mounted(){
     this.listarPart();
 },
 methods:{
     listarPart(){
        var url= 'rankin/lista';
          this.$api.get(url).then(
                ({data}) => {
                   this.arrayAd = data.lista;
                  
                }
            );
     },
     reporteExcel(){
        Swal.fire({
            title: 'Descargar reporte de base de datos',
            text: "Por favor espere y no cierre el navegador.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#013352',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText:'Cancelar',
            }).then((result) => {
            if (result.isConfirmed) {
                this.loader = true;
                var url= 'rankin/reporte';
                this.$api.get(url).then(
                    ({data}) => {
                      console.log(data);
                      this.loader = false;
                       window.open('https://api.coneldoradovalesoro.com.mx/storage/exceles/reporte-basedatos.xlsx', '_blank');                    
                    }
                );
              }
            })
     },
     reportePdf(){
        Swal.fire({
            title: 'Descargar reporte fotográfico de ticket',
            text: "Por favor espere y no cierre el navegador.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#013352',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText:'Cancelar',
            }).then((result) => {
            if (result.isConfirmed) {
                this.loader2 = true;
                var url= 'rankin/pdf';
                this.$api.get(url).then(
                    ({data}) => {
                      console.log(data);
                      this.loader2 = false;
                       window.open('https://api.coneldoradovalesoro.com.mx/reporte-ticket.pdf', '_blank');                    
                    }
                );
              }
            })
     }
 }
}
</script>

<style>

</style>